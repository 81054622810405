import React from "react";
import Layout from "../components/Layout";
import Styles from "./termsConditions.module.scss";

function Termsconditions() {
  return (
    <Layout>
      <div className={Styles.container}>
        <h1>Terms & Conditions</h1>
        <p>Access to and use of sitecheck.hashouttech.com both is provided on the following terms:
</p>
        <ol><li >
           
            
            By using the services provided by sitecheck.hashouttech.com you agree to be bound by these terms. Please read them carefully. If you do not agree to be bound by any or all of the following terms please do not access and use <a ></a>sitecheck.hashouttech.com.
            
          </li>
          <li >
           
           
          You agree to use this website only for lawful purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use of sitecheck.hashouttech.com.
          
         </li>
         <li >
           
           
         sitecheck.hashouttech.com reserves the right at any time in its sole discretion to block registrants and subscribers from certain IP addresses from accessing the service.sitecheck.hashouttech.com reserves rights to block violators of these terms from using its services or restrict their access in any form at any time.

         </li>
         <li >
           
           
         As a user you agree that lawful owners of sitecheck.hashouttech.com own all legal rights, title, copyrights, design rights, and other intellectual property rights (registered and unregistered) in this website.
         </li>
         <li >
           
           
         You may not copy, reproduce, republish, disassemble, decompile, reverse engineer, post, make available to the public, or otherwise use the content of this website (including all software applications, scripts, web-pages, design elements, and graphics) in any way.

         </li>
         <li >
         You understand and agree that sitecheck.hashouttech.com may anonymize and aggregate any data you share with the service and use it without identifying you for purposes of improving the service.
           
          
         </li>
         <li >
           
         sitecheck.hashouttech.com does not guarantee the accuracy, completeness, or performance of the website or any of the content and services. While we try to ensure that all functionality provided is correct no responsibility is accepted by or on behalf of sitecheck.hashouttech.com for any errors or inaccurate content on the website.

          
         </li>
         <li >
         sitecheck.hashouttech.com shall not be liable for any of the following losses or damage (whether such damage or losses were foreseen, foreseeable, known, or otherwise): (a) loss of data; (b) loss of revenue or anticipated profits; (c) loss of business; (d) loss of opportunity; (e) loss of goodwill or injury to reputation; (f) losses suffered by third parties; or (g) any indirect, consequential, special or exemplary damages arising from the use of sitecheck.hashouttech.com and its services regardless of the form of action.
  
         
         </li>
         <li >
         sitecheck.hashouttech.com does not warrant that functions and services available on sitecheck.hashouttech.com will be uninterrupted or error-free, that defects will be corrected, or that sitecheck.hashouttech.com or the servers which make it available are free of technical issues or bugs.
        </li>
         <li >
           
         sitecheck.hashouttech.com free services are made available at no charge for your personal, non-commercial use only. You may not use free sitecheck.hashouttech.com services to sell a product or service and/or use them for business purposes without obtaining a proper license from sitecheck.hashouttech.com.

         </li>
         <li>sitecheck.hashouttech.com may change these terms at any time.
         </li>
          </ol>
      </div>
    </Layout>
  );
}
export default Termsconditions;
